
jQuery(document).ready(function($){
    //you can now use $ as your jQuery object.

    if(navigator.userAgent.match(/iPad|iPhone|iPod/)) {
        $('.parallax-sec').addClass('ios-background');
      }

    
    $(".panel-heading").click(function() {
        $('.panel-heading').removeClass('active');

    if(false == $(this).next().is(':visible')) {
        $(this).addClass('active');
        
    }
      $(this).parent().find('.panel-body').slideToggle('fast');
    
      $(this).parent().find('.arrow').toggleClass('arrow-animate');
      $(".panel-heading").not(this).parent().removeClass('active').find('.panel-body').slideUp('fast');
    });
  
}); 


var brandswiper = new Swiper('.brand-swiper', {
   
   /* 
    slidesPerView: 4,
    slidesPerColumn: 1,
    spaceBetween: 30,
    loop:true,
    autoplay: {
        delay: 1500,
      },    
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }, 
    breakpoints: {
        // when window width is >= 320px

        320: {
            slidesPerView: 3,
            spaceBetween: 20
        },

        480: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        1140: {
            slidesPerView: 4, 
            spaceBetween: 30
        },
        1366: {
            slidesPerView: 5,
            spaceBetween: 30
        },
      }*/
  
  });


    
var videoSwiper = new Swiper('.f2l_slider', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
   
  });


  
var videoSwiper = new Swiper('.video-swiper', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // function to stop youtube video on slidechange
    on: {
        slideChange: function (el) {
          $('.swiper-slide').each(function () {
              var videoSwiper = $(this).find('iframe').get(0);
              if (videoSwiper) {
                videoSwiper.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
              }
          });
        },
    },
  });



$(function() {
  
    var toggle = true;
    var pos = new Object();
    var currentdiv = "";

    $('.js-decision-tool').click(function() {
        currentdiv = $(this);
        animateToFullScreen($(this));

       
     /* if (toggle) {
        animateToFullScreen($(this));
      } else {
        backToOrginal($(this));
      }*/

      //toggle = !toggle;
    });
    $('.close-tile').click(function() {
        backToOrginal(currentdiv);
       // toggle = !toggle;
      });

    function animateToFullScreen($this) {
      var windowHeight = $(window).height() - 50;
      
      $this.css('visibility', 'hidden');
      pos = $this.position();

      $this.css('position', 'absolute');
      $this.css('max-width', 'none');
      $this.css('left', pos.left);
      $this.css('top', pos.top);
      $this.css('z-index', '1');
      $(window).scrollTop(520);

      $this.css('visibility', 'visible');
     
      
      setTimeout(function(){
        $(".close-tile").show();
     }, 800);
     $this.find(".tile--content .tile--description").show();
     $this.children(".tile--content").css('height', '100%');
     $this.find(".tile--content .tile--title").addClass("large");
     $this.find(".tile--content .tile--subtitle").addClass("large");
     $this.find(".tile--content").addClass("padtop");
     $this.addClass("open");
      $this.animate({
        top: '0px',
        left: 0,
        height: '100%',
        width: '100%',
        margin: 0
      }, "slow");
    }

    function backToOrginal($this) {
       // console.log($this);
      $this.css('z-index', '0');
      $this.css('left', '0');
      $this.css('top', '0');
      $this.css('position', 'relative');

      $this.css('width', '48%');
      $this.css('height', 'auto');
      $this.css('margin', '1%');
      //$this.css('height', '100%');
      //$(this).siblings('.tile--description').hide();
      $(".close-tile").hide();
      $this.find(".tile--content .tile--description").hide();
      $this.find(".tile--content .tile--title").removeClass("large");
      $this.find(".tile--content .tile--subtitle").removeClass("large");
      $this.children(".tile--content").css('height', '100%');
      $this.find(".tile--content").removeClass("padtop");
      $this.removeClass("open");
    }
});



$(function () {


  
  
    AOS.init();

    

    /*//////////////////////////////////////
    //  vars
    //////////////////////////////////////*/
    var $document = $(document),
        $window = $(window),
        $body = $("body"),
        $burger = $(".burger-box"),
        $mainLogo = $(".main-logo"),
        $search = $("div.search input"),
        $mainNav = $("nav.main-navigation"),
        $mainContent = $("main.main-content"),
        $formContainer = $("aside.form.modal");

    var canSeeAds = true;

    if (window.youSneakyPieceOf === undefined) {
        canSeeAds = false;
    }

    $('.project_category_dropdown').on("click", function () {
        $('.project_category').fadeToggle({
            duration: 500, // Adjust the duration as needed
            start: function () {
                $(this).css({
                    display: "flex", // Ensure the element is displayed as flex (or any other appropriate display value)
                    opacity: 0, // Initially set the opacity to 0

                });
            },
            // complete: function () {
            //     // Check if the element is visible after toggling
            //     if ($(this).is(':visible')) {
            //         // Use GSAP to animate fade in from top to bottom
            //         gsap.fromTo($(this), { opacity: 0, y: "-50px" }, { opacity: 1, y: "0", duration: 0.5, ease: "power2.inOut" });
            //     }
            // }
        });
    });

     /*//////////////////////////////////////
    //  Burger menu
    //////////////////////////////////////*/
    $burger.on("click", function (event) {
        event.preventDefault();
        if ($(this).hasClass("back")) {
            window.history.back();
            $(this).removeClass("open").addClass("closed").removeClass("back");
        } else if ($(this).hasClass("open")) {
            // close the modal
            $(this).removeClass("open").addClass("closed");
            $body.removeClass("nav-open");
            $body.removeClass("modal-open");
            $body.removeClass("no-scroll");
            $search.blur();
            $formContainer.empty();
            removeSearch();
        } else {
            // open the modal
            $(this).addClass("open").removeClass("closed");
            $body.addClass("nav-open");
            $body.addClass("no-scroll");
            if (!Modernizr.touchevents) {
                $search.focus();
            }
        }
    });
    
    var getCookie = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

  
       

     
        
    
      

    /*//////////////////////////////////////
    // Persona, Creating a User
    //////////////////////////////////////*/
    var personaCreateUser = function (email, callback) {
        if (persona) {
            var sent = false;
            var url = "/api/v1/persona/person/create";

            if (sent === false) {
                $.ajax({
                    url: url,
                    type: "GET",
                    data: {
                        cookie_id: getCookie("persona"),
                        email: email,
                    },
                })
                    .done(function () {
                        console.log("Email collected");
                        sent = true;
                    })
                    .fail(function () {
                        console.log("error");
                    })
                    .always(function () {
                        console.log("complete");
                        if (callback) {
                            callback();
                        }
                    });
            }
        }
    };

    /*//////////////////////////////////////
    // Prospect Login
    //////////////////////////////////////*/
    var prospectLogin = function (signInData) {
        $.ajax({
            url: $(this).find('input[name="action"]').val(),
            type: "POST",
            data: signInData,
        })
            .done(function (resp) {
                console.log(resp);
                if (resp.error) {
                    alert(resp.error);
                } else {
                    window.location = "/login-route";
                }
            })
            .fail(function () {
                console.log("error");
            })
            .always(function () {
                console.log("complete");
            });
    };

    $("section.login form").on("submit", function (event) {
        event.preventDefault();

        var data = $(this).serialize();
        var email = $(this).find("input#email").val();
        var loginEmail = $(this).find("input#loginName").val();

        ga("send", "event", "persona", "create", loginEmail + " | " + email);

        if (testEmail(email)) {
            personaCreateUser(email, prospectLogin(data));
        } else {
            prospectLogin(data);
        }
    });

    /*//////////////////////////////////////
    // Mailchimp Register
    //////////////////////////////////////*/
    var mailchimpSignup = function (
        email,
        firstName,
        lastName,
        company,
        jobTitle
    ) {
        $.ajax({
            url: "/",
            type: "POST",
            // dataType: 'jsonp',
            data: {
                action: "mailchimpSubscribe/list/Subscribe",
                email: email,
                "mcvars[FNAME]": firstName,
                "mcvars[LNAME]": lastName,
                "mcvars[COMPANY]": company,
                "mcvars[JOBTITLE]": jobTitle,
            },
        })
            .done(function () {
                console.log("mc success");
                $.cookie("hhcc_mc", true);
            })
            .fail(function () {
                console.log("mc error");
            })
            .always(function () {
                console.log("mc complete");
            });
        // $.post('/',)
    };

    $body.find("section.lead-gen form").on("submit", function (event) {
        event.preventDefault();

        var email = $(this).find("#email").val();
        var fullName = $(this).find("#fullname").val();

        var fullNameArray = fullName.split(" "),
            firstName = fullNameArray[0],
            lastName = fullNameArray[1];

        mailchimpSignup(email, firstName, lastName, null, null);
        $(this).fadeOut(function () {
            $(this)
                .parent()
                .find("h5")
                .html(
                    "Thanks. You're all signed up.<br/> Be on the lookout for the latest Hill Holliday news."
                );
            $(this).remove();
        });
    });

    /*//////////////////////////////////////
    // Anniversary Party Ideas
    //////////////////////////////////////*/
    $("button.idea").on("click", function (event) {
        event.preventDefault();
        window.history.back();
    });

    /*//////////////////////////////////////
    // Contact Us Form
    //////////////////////////////////////*/
    $body.on("submit", "form.contact-us", function (event) {
        event.preventDefault();

        // get the whole form
        var $form = $(this);

        // get all the data by itself
        var fullName = $form.find("#fullname").val(),
            company = $form.find("#company").val(),
            jobTitle = $form.find("#title").val(),
            email = $form.find("#email").val(),
            message = $form.find("#message").val(),
            interest = $form.find('select[name="subject"]').val(),
            receiveEmail = $form.find("#emails").val();

        // add new email to body of the form
        $("<input />")
            .attr("type", "hidden")
            .attr("name", "message[Email]")
            .attr("value", email)
            .appendTo($form);

        // get the full name in pieces
        var fullNameArray = fullName.split(" "),
            firstName = fullNameArray[0],
            lastName = fullNameArray[1];

        // full data for submission
        var fullData = $form.serialize();

        // disabling inputs
        $form.find('input[type="submit"]').attr("disabled", true);
        $form.find('input[type="submit"]').attr("value", "Sending...");

        if ($form[0].checkValidity()) {
            // post the dataz
            $.post("/", fullData, function (response) {
                if (response.success) {
                    $form.fadeOut(function () {
                        $(this).empty();
                        $(this).append(
                            "<h3>Thank you</h3><p>Thanks for your message. We'll be in contact soon.</p>"
                        );
                        $form.fadeIn();

                        // create user in persona
                        personaCreateUser(email, null);

                        // sign em up for mailchimp
                        // if($form.find('#emails').is(':checked')){
                        //    mailchimpSignup(email, firstName, lastName, company, jobTitle);
                        //}

                        var fields = [
                            {
                                firstname: firstName,
                                lastname: lastName,
                                email: email,
                                company: company,
                                jobtitle: jobTitle,
                                type: interest,
                            },
                        ];

                        dataLayer.push({
                            'event': 'formSubmission',
                            'formType': 'Contact us',
                            'formPosition': 'Contact us page'
                        });

                        // send form data to hubspot
                        // $.ajax({
                        //     url: "https://api.hsforms.com/submissions/v3/integration/submit/2405564/b1f1d319-d3b4-4cea-b503-34434b3549ce",
                        //     type: "POST",
                        //     dataType: "json",
                        //     contentType: "application/json",
                        //     data: JSON.stringify(fields),
                        //     success: function (data) {
                        //         console.log(data);
                        //     },
                        // });
                    });
                } else {
                    alert(
                        "Sorry there was an error with your submission. Please try again later."
                    );
                }
            });
        } else {
            $form.find('input[type="submit"]').attr("disabled", false);
            $form.find('input[type="submit"]').val("Submit");
            alert(
                "Sorry there is an error with your form. Please double check your input and try again."
            );
        }
    });

     

    /*//////////////////////////////////////
    // Download Asset
    //////////////////////////////////////*/
    $body.on("submit", "form.download", function (event) {
        event.preventDefault();

        var $form = $(this),
            whitePaperUrl = $form.find('input[name="whitepaper-url"]').val();

        var fullName = $form.find("#fullname").val(),
            company = $form.find("#company").val(),
            jobTitle = $form.find("#title").val(),
            email = $form.find("#email").val();

        // get the full name in pieces
        var fullNameArray = fullName.split(" "),
            firstName = fullNameArray[0],
            lastName = fullNameArray[1];

        if ($form[0].checkValidity()) {
            personaCreateUser(email, null);

            $form.fadeOut(function () {
                $(this).addClass("success");
                $(this).empty();
                $(this).append(
                    '<h3>Thank you</h3><p>Click the button below to download.</p><a class="cta download-whitepaper" href="' +
                        whitePaperUrl +
                        '" download><span>Download Now</span></a>'
                );
                $form.fadeIn();

                if (window.ga && ga.create) {
                    ga("set", "page", "bei_" + whitePaperUrl);
                    ga("send", "pageview");
                }

                $form.on("click", "a", function () {
                    setTimeout(function () {
                        $burger.click();
                    }, 2000);
                });
            });

            if ($form.find("#emails").is(":checked")) {
                mailchimpSignup(email, firstName, lastName, company, jobTitle);
            }
        } else {
            alert(
                "Sorry there is an error with your form. Please double check your input and try again."
            );
        }
    });

    /*//////////////////////////////////////
    // Test Email String
    //////////////////////////////////////*/
    var testEmail = function (email) {
        var matchEmail = email.match(
            /^[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\.[a-z]{2,8}$/
        );
        return matchEmail;
    };

    /*//////////////////////////////////////
    // Form validation on form input items
    //////////////////////////////////////*/
    $("form input, form textarea").each(function () {
        if ($(this).val().length > 0) {
            $(this).addClass("dirty");
        }
    });

    $body.on("keyup", "form input, form textarea", function () {
        var $el = $(this);
        var val = $el.val();
        var length = $el.val().length;

        // input type = text validation
        if ($el[0].type === "text") {
            $el.addClass("invalid");
            $el.removeClass("valid");
            if (length > 2) {
                $el.addClass("valid");
                $el.removeClass("invalid");
            }
        }

        // input type= email
        if ($el[0].type === "email") {
            $el.addClass("invalid");
            $el.removeClass("valid");
            // var matchEmail = val.match(/^[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\.[a-z]{2,4}$/);

            if (testEmail(val)) {
                $el.addClass("valid");
                $el.removeClass("invalid");
            }
        }

        // always add dirty if val is larger than 0
        if (length > 0) {
            $el.addClass("dirty");
        } else {
            $el.removeClass("dirty");
        }
    });

    /*//////////////////////////////////////
    // Mailchimp form Submissions
    //////////////////////////////////////*/
    // $('form button.cta').on('click',function(event){
    //     event.preventDefault();
    //     $(this).parent().parent().submit();
    // });
    // $('form.connect-signup').on('submit',function(event){
    //     $form = $(this);

    //     event.preventDefault();

    //     if($form[0].checkValidity()){
    //         $.ajax({
    //             url: $form.attr('action'),
    //             dataType: 'jsonp',
    //             type: 'POST',
    //             data: $form.serialize()
    //         })
    //         .done(function() {
    //             console.log('success');
    //             $form.parent().append('<div class="thank-you"><h5>Thank you for signing up.</h5><p>To complete the subscription process, please click the link in the email we just sent you.</p>');
    //             setTimeout(function(){
    //                 $form.parent().addClass('subscribed');
    //             },500);
    //         })
    //         .fail(function() {
    //             console.log('error');
    //             alert('Sorry, there was an error with your submission. Please try again.');
    //         })
    //         .always(function(data) {
    //             console.log('complete');
    //         });
    //     }
    //     else{
    //         alert('Sorry, please make sure your email is correct and try again.');
    //     }
    // });

    /*//////////////////////////////////////
    //  you have an ad blocker on, sneaky
    //////////////////////////////////////*/
    // todo: come back and revamp this
    // if(canSeeAds === false && $body.find($('section.client-projects.with-caption')).length > 0){
    //     if(Modernizr.emoji){
    //         $body.find($('header.banner p:first-of-type')).after('<p>We won’t judge you for using using an AdBlocker, but we still appreciate you coming to check out our work 😉.</p>');
    //     }
    //     else{
    //         $body.find($('header.banner p:first-of-type')).after('<p>We won’t judge you for using using an AdBlocker, but we still appreciate you coming to check out our work ;).</p>');
    //     }
    // }

    /*//////////////////////////////////////
    //  Mobile hovers
    //////////////////////////////////////*/
    var checkMobileHovers = function () {
        /*//////////////////////////////////////
        //  work logos highlight
        //////////////////////////////////////*/
        var checkClientList;
        if ($("ul.client-list").length > 0 && Modernizr.touchevents) {
            checkClientList = setInterval(function () {
                $("ul.client-list li").each(function () {
                    if (isElementCenterInViewport($(this))) {
                        $(this).find("a").addClass("active");
                    } else {
                        $(this).find("a").removeClass("active");
                    }
                });
            }, 200);
        } else {
            clearInterval(checkClientList);
        }

        /*//////////////////////////////////////
        //  culture highlight
        //////////////////////////////////////*/
        var checkEmployees;
        if ($("ul.employee-list").length > 0 && Modernizr.touchevents) {
            checkEmployees = setInterval(function () {
                $("ul.employee-list li").each(function () {
                    if (isElementCenterInViewport($(this))) {
                        $(this).addClass("active");
                    } else {
                        $(this).removeClass("active");
                    }
                });
            }, 200);
        } else {
            clearInterval(checkEmployees);
        }
    };
    checkMobileHovers();

    var checkCultureArrows = function () {
        var checkArrows;
        if ($("#employees").length > 0 && !Modernizr.touchevents) {
            checkArrows = setInterval(function () {
                if (isElementInViewport($("a.prev-link svg"))) {
                    $("a.prev-link, a.next-link").removeClass("show");
                }
            }, 1000);
        } else {
            clearInterval(checkArrows);
        }
    };
    checkCultureArrows();


    var masonry = function () {
      /*  $("ul.jobs-list-grouped").isotope({
            itemSelector: "ul.jobs-list-grouped > li",
            layoutMode: "masonry",
            transitionDuration: 0,
            masonry: {
                gutter: 0,
            },
        });
        if ($("ul.jobs-list-grouped").length > 0 && $(window).width() > 700) {
        }*/
    };
    masonry();

    

    /*//////////////////////////////////////
    //  Video - One Up
    //////////////////////////////////////*/
    var playOneUp = function ($el, animationTime) {
        var iframe = $el.find("iframe")[0];
        var player = $f(iframe);

        player.addEvent("ready", function () {
            player.addEvent("pause", onPause);
            player.addEvent("finish", onFinish);
            player.addEvent("playProgress", onPlayProgress);
        });

        function onPause() {
            console.log("video paused");
        }

        function onFinish() {
            console.log("video finished");
            player.api("seekTo", 0);
            $el.parent().removeClass("video-active");
        }

        function onPlayProgress(data) {
            console.log(data.seconds + "s played");
        }

        $el.parent().addClass("video-active");
        player.api("play");
    };

    $body.on(
        "click",
        "section.video-block.one-up .video-wrapper",
        function (event) {
            if (!Modernizr.touchevents) {
                event.preventDefault();

                var $el = $(this);
                var animationTime = 0.6 * 1000;

                playOneUp($el, animationTime);
            }
        }
    );

    /*//////////////////////////////////////
    //  Video - Modal
    //////////////////////////////////////*/
    var openVideoModal = function ($el, $elParent, animationTime) {
        // prevent scrolling, add in the video bg div
        $body.addClass("no-scroll");
        if (!$body.find(".video-bg").length) {
            $body.append('<div class="video-bg"></div>');
            $elParent.append('<div class="close-box"></div>');
        }

        // animate the video bg in
        setTimeout(function () {
            $body.find("div.video-bg").addClass("video-init");
        }, 50);

        // add player selectors
        var iframe = $el.find("iframe")[0];
        var player = $f(iframe);

        // add event listeners
        player.addEvent("ready", function () {
            player.addEvent("pause", onPause);
            player.addEvent("finish", onFinish);
            player.addEvent("playProgress", onPlayProgress);
        });

        // define events
        function closeVideo() {
            $el.removeClass("video-active");
            $el.addClass("video-fadeout");
            $body.find(".close-box").remove();
            $body.find("div.video-bg").addClass("video-over");
            setTimeout(function () {
                // remove the video modal entirely
                $body.removeClass("no-scroll");
                $body.find(".video-bg").remove();
                $el.removeClass("video-fadeout");
                player.api("seekTo", 0);
            }, animationTime);
        }

        function onPause() {
            console.log("video paused");
        }

        function onFinish() {
            console.log("video finished");
            // remove the state classes around the video being open
            closeVideo();
        }

        function onPlayProgress(data) {
            //console.log(data.seconds + 's played');
        }

        // play the video after it's faded in
        setTimeout(function () {
            $el.addClass("video-active");
            player.api("play");
        }, animationTime);

        // remove the video
        $body.find("div.close-box").on("click", function (event) {
            event.preventDefault();
            player.api("pause");
            closeVideo();
        });
    };

    /*//////////////////////////////////////
    //  Video - Two Up & Three Up
    //////////////////////////////////////*/
    $body.on(
        "click",
        "section.video-block.two-up .video-wrapper, section.video-block.three-up .video-wrapper, section.pullquote-block .video-wrapper",
        function (event) {
            if (!Modernizr.touchevents) {
                event.preventDefault();

                var $el = $(this);
                var $elParent = $(this).parent();
                var animationTime = 0.6 * 1000;

                openVideoModal($el, $elParent, animationTime);
            }
        }
    );

    $body.on("click", "header.banner button.launch-video", function (event) {
        if (!Modernizr.touchevents) {
            event.preventDefault();
            var $el = $(this).parent().parent();
            var $elParent = $(this).parent().parent();
            var animationTime = 0.6 * 1000;

            openVideoModal($el, $elParent, animationTime);
        }
    });

    /*//////////////////////////////////////
    //  Banner Buttons
    //////////////////////////////////////*/
    $body.on("click", "button.launch-modal", function (event) {
        event.preventDefault();

        var form = $(this).data("form");

        $body.addClass("modal-open");
        $burger.addClass("open").removeClass("closed");
        $body.addClass("no-scroll");

        if (window.ga && ga.create) {
            ga("set", "page", "bei_" + form);
            ga("send", "pageview");
        }

        $.ajax({
            url: "/forms/" + form,
            type: "GET",
        })
            .done(function (data) {
                $formContainer.append($(data));
                setTimeout(function () {
                    $formContainer.find("form").addClass("active");
                }, 100);
                console.log("success");
            })
            .fail(function () {
                console.log("error");
            })
            .always(function () {
                console.log("complete");
            });
    });

    /*//////////////////////////////////////
    //  Global Search
    //////////////////////////////////////*/
    // helper functions
    var findIndexByValue = function (arraytosearch, key, valuetosearch) {
        for (var i = 0; i < arraytosearch.length; i++) {
            if (arraytosearch[i][key] === valuetosearch) {
                return arraytosearch[i];
            }
        }
        return null;
    };

    var getElPosition = function (el) {
        var xPosition = 0;
        var yPosition = 0;

        while (el) {
            xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
            yPosition += el.offsetTop - el.scrollTop + el.clientTop;
            el = el.offsetParent;
        }
        return {
            x: xPosition,
            y: yPosition,
        };
    };

    var mode = function (array) {
        if (array.length === 0) {
            return null;
        }
        var modeMap = {};
        var maxEl = array[0],
            maxCount = 1;
        for (var i = 0; i < array.length; i++) {
            var el = array[i];
            if (modeMap[el] == null) {
                modeMap[el] = 1;
            } else {
                modeMap[el]++;
            }
            if (modeMap[el] > maxCount) {
                maxEl = el;
                maxCount = modeMap[el];
            }
        }
        return maxEl;
    };

    Array.prototype.contains = function (v) {
        for (var i = 0; i < this.length; i++) {
            if (this[i] === v) {
                return true;
            }
        }
        return false;
    };

    Array.prototype.unique = function () {
        var arr = [];
        for (var i = 0; i < this.length; i++) {
            if (!arr.contains(this[i])) {
                arr.push(this[i]);
            }
        }
        return arr;
    };

    var removeInstanceOfElement = function (elem, arr) {
        var index = 0;

        while (index < arr.length) {
            if (arr[index] === elem) {
                arr.splice(index, 1);
                index = 0;
            } else {
                ++index;
            }
        }
        return arr;
    };

    var decodeHtml = function (html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };

    var populateSearch = function (searchResults) {
        var searchMarkup = "";
        searchMarkup += '<div class="search-category-container">';
        searchMarkup += "<ol>";
        for (var k = 0; k < searchResults.length; k++) {
            // none special results
            if (searchResults[k].special !== true) {
                searchMarkup += "<li>";
                searchMarkup += '<a href="' + searchResults[k].url + '">';
                if (searchResults[k].thumbnail !== "") {
                    searchMarkup +=
                        '<img src="' +
                        searchResults[k].thumbnail +
                        '" alt="' +
                        searchResults[k].content +
                        '">';
                }
                searchMarkup +=
                    '<span class="results-category">' +
                    searchResults[k].section +
                    "</span>";
                searchMarkup +=
                    '<span class="results-title">' +
                    decodeHtml(searchResults[k].content) +
                    "</span>";
                searchMarkup += "</a>";
                searchMarkup += "</li>";
            }
            // super special results
            else {
                searchMarkup += '<li class="expanded">';
                if (searchResults[k].thumbnail !== "") {
                    if (searchResults[k].url !== "") {
                        searchMarkup +=
                            '<a href="' + searchResults[k].url + '">';
                    }

                    searchMarkup +=
                        '<img src="' + searchResults[k].thumbnail + '" alt="">';

                    if (searchResults[k].url !== "") {
                        searchMarkup += "</a>";
                    }
                }
                searchMarkup +=
                    '<div class="expanded-content">' +
                    decodeHtml(searchResults[k].content) +
                    "</div>";
                searchMarkup += "</li>";
            }
        }
        searchMarkup += "</ol>";
        searchMarkup += "</div>";

        $mainNav.find("div.search-results").append(searchMarkup);
    };

    var initSearch2 = function (searchResults) {
        populateSearch(searchResults);
        if (searchResults.error) {
            $("div.search-results").append(
                '<p class="search-error">' + searchResults.error + "</p>"
            );
        }
    };

    var timer,
        delay = 300;
    var showSearch = function (query) {
        $mainNav.addClass("search-active");

        $mainNav.find("div.search-results").html("");

        $search.parent().find("p").addClass("searching");

        if (window.ga && ga.create) {
            ga("send", "event", "search", "term", query);
        }

        clearTimeout(timer);
        timer = setTimeout(function () {
            $.ajax({
                url: "/search?q=" + query,
                type: "GET",
            })
                .done(function (response) {
                    initSearch2(response);
                })
                .fail(function () {
                    console.log("error");
                })
                .always(function () {
                    //console.log("complete");
                    $search.parent().find("p").removeClass("searching");
                });
        }, delay);
    };

    var removeSearch = function () {
        $mainNav.removeClass("search-active");
        $mainNav.find("div.search-results").html("");
        $search.find("button").hide();
        $search.val("");
    };

    $search.on("propertychange click keyup input paste", function () {
        var query = $(this).val();

        if (query.length > 0) {
            showSearch(query);
        } else {
            removeSearch();
        }
    });

    $document.on("keyup", function (event) {
        if ($body.hasClass("nav-open")) {
            if (event.keyCode === 27) {
                // esc
                if ($search.val().length > 0) {
                    removeSearch(); // remove search
                } else {
                    $burger.click(); // if search is empty, close the nav
                }
            }
        }
    });

    /*//////////////////////////////////////
    //  videos  TODO
    //////////////////////////////////////*/
    var playing = false;

    var playVideo = function ($el) {
        setTimeout(function () {
            playing = true;
        }, 300);
        $body.addClass("movie-time");
    };

    var stopVideo = function (video, src) {
        playing = false;
        $body.removeClass("movie-time");
    };

    $body.on("click", function (event) {
        if (playing === true) {
            // TODO make this dynamic
            var video = $("section.homepage-hero").find("video");
            stopVideo(video, "/video/hhcc-rebrand.mp4");
        }
    });

    $("section.homepage-hero button.inline-button").on(
        "click",
        function (event) {
            event.preventDefault();

            playVideo($(this).parent().parent().parent());
        }
    );

    /*//////////////////////////////////////
    //  shrink logo
    //////////////////////////////////////*/
    var currentScroll = function () {
        var current = $(document).scrollTop();
        return current;
    };

    var checkHeader = function () {
        if (currentScroll() > $(window).height() - 72) {
            $body.addClass("min-logo");
        } else {
            $body.removeClass("min-logo");
        }
    };

    setInterval(function () {
        if (location.pathname === "/") {
            checkHeader();
        }
    }, 500);

    /*//////////////////////////////////////
    //  Collapsable headings
    //////////////////////////////////////*/
    $("div.content-toggle").on("click", function (event) {
        var $parent = $(this).parents("section");
        $(this).toggleClass("collapsed");
        $parent.find("div.content").toggleClass("collapsed");
    });

    /*//////////////////////////////////////
    //  Work Navigation Scroll
    //////////////////////////////////////*/
    $(".case-study-nav a").on("click", function (event) {
        event.preventDefault();
        var target = $(this).attr("href");
        $("html,body").animate(
            {
                scrollTop: $(target).offset().top - 100,
            },
            750
        );
    });
    
    if ($(".case-study-nav").length > 0 && !Modernizr.touchevents) {
        var initialHeight = $(".case-study-nav").offset().top;
        $(window).on("scroll", function () {
            if (currentScroll() > initialHeight) {
                $body.addClass("pinned");
            } else {
                $body.removeClass("pinned");
            }
        });
    }

    //////////////////////////////////////
    //  work modals
    //////////////////////////////////////
    function modalClick() {
        $(".project-list a.modal-case").off("click");
        $(".project-list a.modal-case").on("click", function (event) {
            var $isAjaxing = $isAjaxing || null;
            event.preventDefault();

            $burger.removeClass("closed").addClass("open");
            console.log($(this).attr("href"));
            $.ajax({
                type: "GET",
                async: true,
                url: $(this).attr("href"),
                success: function (response) {
                    $("aside.modal.form").html(
                        '<div class="content">' + response + "</div>"
                    );
                    $("aside.modal.form").append(
                        '<div class="close-box"></div>'
                    );
                    $body.addClass("modal-open");
                    $body.addClass("no-scroll");
                },
            });
        });
    }

    $(document).ready(modalClick);
    $(document).ajaxComplete(modalClick);

    /*//////////////////////////////////////
    //  HOMEPAGE STUFF
    //////////////////////////////////////*/
    var homepageInit = function () {
        if ($("section.homepage-hero").length > 0) {
            $body.on("mousemove", function () {
                $("section.homepage-hero").addClass("active");
                $("div.layer-tint").addClass("active");
            });
            if (Modernizr.touchevents) {
                setTimeout(function () {
                    $("section.homepage-hero").addClass("active");
                    $("div.layer-tint").addClass("active");
                }, 3000);
            }
        }
    };
    homepageInit();

    
   



    /*/////////////////////////
    // detect if element is in viewport
    /////////////////////////*/
    var isElementInViewport = function ($el) {
        //special bonus for those using jQuery
        if (typeof jQuery === "function" && $el instanceof jQuery) {
            $el = $el[0];
        }

        if ($el == undefined) {
            return false;
        }

        var rect = $el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
                (window.innerHeight ||
                    $(window).height()) /*or $(window).height() */ &&
            rect.right <=
                (window.innerWidth ||
                    $(window).width()) /*or $(window).width() */
        );
    };

    var isElementCenterInViewport = function ($el) {
        //special bonus for those using jQuery
        if (typeof jQuery === "function" && $el instanceof jQuery) {
            $el = $el[0];
        }

        var rect = $el.getBoundingClientRect();
        return (
            rect.top >= $(window).height() / 5 &&
            rect.left >= 0 &&
            rect.bottom <= $(window).height() - $(window).height() / 5 &&
            rect.right <= $(window).width()
        );
    };

    /*/////////////////////////
    // Split up query strings
    /////////////////////////*/
    var getQueryVariable = function (variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }

        return false;
    };

    /*//////////////////////////////////////
    // Culture active tabs
    //////////////////////////////////////*/
    $("nav.employee-nav a").on("click", function (event) {
        event.preventDefault();

        $("nav.employee-nav a").each(function () {
            $(this).removeClass("active");
        });
    });

    var activeCulture = function () {
        var active = getQueryVariable("filter");
        $("nav.employee-nav a").each(function () {
            $(this).removeClass("active");
        });
        $("nav.employee-nav")
            .find("." + active)
            .addClass("active");
    };
    setInterval(function () {
        activeCulture();
    }, 200);

    /*/////////////////////////
    // open data-target in new tabs
    /////////////////////////*/
    var popupWindow = function () {
        $("a[data-popup]").on("click", function (event) {
            event.preventDefault();
            window.open($(this)[0].href);
        });
    };
    popupWindow();

    /*/////////////////////////
    // Open social links in new windows
    /////////////////////////*/
    var popItUp = function (url, height, width) {
        newwindow = window.open(
            url,
            "name",
            "height=" + height + ",width=" + width
        );
        if (window.focus) {
            newwindow.focus();
        }
        return false;
    };

    $("a.popup").on("click", function (event) {
        event.preventDefault();
        popItUp($(this).attr("href"), 400, 600);
    });
    /////////////////////////////////
    // Mobile Screenshot Carousel
    /////////////////////////////////
    $(window).on("viewChanged popstate", function () {
        $(".mobile-carousel").slick({
            dots: true,
            infinite: true,
            speed: 750,
            slidesToShow: 6,
            slidesToScroll: 6,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    });
    // HARD REFRESH INIT SLICK
    $(".mobile-carousel").slick({
        dots: true,
        infinite: true,
        speed: 750,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });

    $(".relatedwork-carousel").slick({
        autoplay: false,
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    rows: 0
    });
    
});

/*
var mapStyle = [
    { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
    {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#bdbdbd" }],
    },
    {
        featureType: "administrative.neighborhood",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    { featureType: "poi.business", stylers: [{ visibility: "off" }] },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [{ color: "#cbcbcb" }],
    },
    {
        featureType: "road.arterial",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dadada" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{ color: "#7dbc38" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
    },
    { featureType: "road.local", stylers: [{ visibility: "off" }] },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
    },
    { featureType: "transit", stylers: [{ visibility: "off" }] },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#c9c9c9" }],
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#323232" }],
    },
    {
        featureType: "water",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
    },
];

var $map = $("#map-block");

function initMap() {
    var map;
    map = new google.maps.Map($map[0], {
        center: { lat: 42.3588658, lng: -71.0568298 },
        zoom: 4,
        styles: mapStyle,
    });
    var bounds = new google.maps.LatLngBounds();

    // Create markers on map for each location
    locationsArray.forEach(function (location) {
        var position = new google.maps.LatLng(location.lat, location.long);
        var marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: {
                anchor: new google.maps.Point(30, 60),
                scaledSize: new google.maps.Size(40, 60),
                url: location.marker,
            },
        });
        if (location.mapThumbnail != undefined) {
            var infoWindow = new google.maps.InfoWindow({
                content:
                    '<div class="marker-container"><img src="' +
                    location.mapThumbnail +
                    '"><h4>' +
                    location.title +
                    "</h4>" +
                    location.mapText +
                    "</div>",
            });
        } else {
            var infoWindow = new google.maps.InfoWindow({
                content:
                    '<div class="marker-container"><h4>' +
                    location.title +
                    "</h4>" +
                    location.mapText +
                    "</div>",
            });
        }
        marker.addListener("click", function () {
            infoWindow.open(map, marker);
        });
        marker.setMap(map);
        bounds.extend(marker.getPosition());
    });
    if (locationsArray.length > 1) {
        map.fitBounds(bounds);
    }
}
*/
function persona() {
    /* Global Vars */
    this.baseURL = ""; //http://persona.hhcctech.com
    this.key = "";

    /* Persona Initiation Function */
    this.init = function () {
        /* initialize the library with app call*/
        var callbackName =
            "jsonp_callback_" + Math.round(100000 * Math.random());

        window[callbackName] = function (data) {
            delete window[callbackName];
            document.body.removeChild(script);
            document.cookie =
                "persona=" +
                data.cookie_id +
                "; expires=Thu, 31 Dec 2030 12:00:00 UTC";

            /* Track each page by default */
            persona.trackPage();
        };

        var url = this.baseURL + "/api/v1/app?key=" + this.key;
        var script = document.createElement("script");
        script.src =
            url +
            (url.indexOf("?") >= 0 ? "&" : "?") +
            "callback=" +
            callbackName;
        document.body.appendChild(script);
    };

    this.trackEvent = function (eventID, customData, callback) {
        var callbackName =
            "jsonp_callback_" + Math.round(100000 * Math.random());
        window[callbackName] = function (data) {
            delete window[callbackName];
            document.body.removeChild(script);

            if (callback != null) {
                callback(data);
            }
        };

        var url =
            this.baseURL +
            "/api/v1/event/track?key=" +
            this.key +
            "&event_id=" +
            eventID +
            "&custom_data=" +
            encodeURI(customData);
        var script = document.createElement("script");
        script.src =
            url +
            (url.indexOf("?") >= 0 ? "&" : "?") +
            "callback=" +
            callbackName;
        document.body.appendChild(script);
    };

    this.trackPage = function (callback) {
        var callbackName =
            "jsonp_callback_" + Math.round(100000 * Math.random());
        window[callbackName] = function (data) {
            delete window[callbackName];
            document.body.removeChild(script);

            if (callback != null) {
                callback(data);
            }
        };

        if (typeof console.log === "function") {
            var pageTitle = document.title;
        } else {
            var pageTitle = "";
        }

        /* Get the referrer */
        var referrer = document.referrer;

        if (referrer != "") {
            if (location.hostname == referrer.split("/")[2]) {
                referrer = "";
            }
        }

        var url =
            this.baseURL +
            "/api/v1/event/track_page?key=" +
            this.key +
            "&url=" +
            encodeURIComponent(window.location.href) +
            "&title=" +
            encodeURIComponent(pageTitle) +
            "&ref=" +
            encodeURIComponent(referrer);
        var script = document.createElement("script");
        script.src =
            url +
            (url.indexOf("?") >= 0 ? "&" : "?") +
            "callback=" +
            callbackName;
        document.body.appendChild(script);
    };

  

 // Wait for the document to be ready




  
}
